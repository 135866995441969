.headerContainer {
  padding: 20px;
  background: white;
}

.headerLeftSide,
.headerMiddleSide,
.headerRightSide {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.headerLeftSide {
  justify-content: flex-start;
}
.headerMiddleSide {
  justify-content: center;
}
.headerRightSide {
  justify-content: flex-end;
}

.headerLogoContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.headerLogo {
  margin-right: 20px;
}
.headerTitle h3 {
  color: #6b7cff;
  font-weight: 600;
  font-size: 24px;
}

@media screen and (max-width: 1000px) {
  .headerTitle h3 {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .headerContainer {
    padding: 20px 5px;
  }
}
